import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import RefProgImg from "../images/connect.png"
import { Link } from "gatsby"

import image1 from "../images/it-job.png"
import image2 from "../images/search-job.png"
import image3 from "../images/connection.png"
import image4 from "../images/your-potential-earnings.jpg"

const ClientReferralProgram = () => {
  return (
    <Layout>
      <SEO title="Referral Program" />
      <div className="r-program-page">
        <Container className="pb-100" style={{ paddingTop: "200px" }}>
          <h1 className="bottom-underline text-center text-blue">
            CLIENT REFERRAL PROGRAM
          </h1>
        </Container>

        <Container>
          <Row>
            <Col lg="7 d-flex justify-content-center align-items-center">
              <div>
                {" "}
                <h1
                  className="ps-main-headline"
                  style={{
                    color: "#152c4b",
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  Unlock the Power of Connections with PeerSource
                </h1>
                {/* <h3 style={{ fontSize: "25px", fontWeight: "100" }}>
                  Over 60% of all candidates find their next job through
                  networking.
                </h3> */}
              </div>
            </Col>
            <Col lg="5">
              <img className="w-100" src={RefProgImg} alt="Payroll Services" />
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          style={{
            backgroundColor: "#f9f9f9",
            padding: "100px 0 100px",
            marginTop: "100px",
          }}
        >
          <Container>
            <div style={{ maxWidth: "1100px", margin: "0 auto" }}>
              <div className="">
                {/* <h2 className="mb-4">
                  Why not make some easy money from that?
                </h2> */}

                <h3 className="mb-4">
                  Transform Introductions into Opportunities
                </h3>
                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  Did you know? Companies are 4 times more likely to partner
                  with us through a personal introduction. That's where you come
                  in—and where the benefits start rolling your way.
                </p>
              </div>
              <div className="mt-5">
                <h2 className="mb-4">
                  Welcome to the PeerSource Client Referral Program
                </h2>

                <h3 className="mb-4">How It Works:</h3>
                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  <ol>
                    <li>
                      <b>Make an Introduction:</b> Connect us with someone you
                      know who has hiring authority.
                    </li>
                    <li>
                      <b>We Place, You Earn:</b> If we successfully fill a
                      position for them, you'll receive up to $10,000 as a
                      one-time thank you.
                    </li>
                  </ol>
                </p>

                <div className="my-4">
                  <img
                    className="object-scale-down-img-4"
                    src={image4}
                    alt="Your Potential Earnings"
                    width={850}
                    height={500}
                    sizes="(max-width: 720px) 100vw, 800px"
                  />
                </div>

                {/* <h3 className="mb-4">Your Potential Earnings:</h3>
                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  <ul>
                    <li>
                      <b>15,000 Fee or Below:</b> You get a $5,000 bonus.
                    </li>
                    <li>
                      <b>Between $15,000 and $25,000 Fee:</b> You earn a $7,500
                      referral bonus.
                    </li>
                    <li>
                      <b>$25,000 Fee or Above:</b> You receive a $10,000 bonus.
                    </li>
                  </ul>
                </p> */}
              </div>
              <div className="mt-5">
                <h2 className="mb-4">Why Is This Possible?</h2>

                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  In our line of work, placing a single candidate typically
                  secures a fee between 18% to 30% of their annual salary. For
                  example, placing a software developer with a $100,000 salary
                  could earn a recruiting firm such as PeerSource a fee up to
                  $30,000—making your referral bonus of $7,500 to $10,000 not
                  just generous, but entirely feasible.
                </p>
              </div>
              <div className="mt-5">
                <h2 className="mb-4">Why PeerSource?</h2>

                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  You might wonder why your referral should trust us. If we've
                  reached out to you about this program, it's likely because
                  you've seen our work firsthand. Whether as a candidate, a
                  client, or through our stellar {""}
                  <a href="/technology/testimonials">
                    Google Reviews and client testimonials
                  </a>
                  , you know we exceed expectations. We’re confident in our
                  ability to make you look good through your referrals.
                </p>
              </div>
              <div className="mt-5">
                <h2 className="mb-4">Program Details:</h2>

                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  <ul>
                    <li>
                      <b>Simple Introduction: </b>A straightforward email
                      connecting us with a hiring authority you know.
                    </li>
                    <li>
                      <b>Timely Placement: </b> The placement must occur within
                      12 months of your introduction.
                    </li>
                    <li>
                      <b>Sustained Employment: </b> The candidate must remain
                      employed for at least 4 months before we issue your
                      referral bonus. Note that this program is limited to one
                      referral bonus per company.
                    </li>
                    <li>
                      <b>Charitable Option:</b> Prefer to give back? We can
                      donate your referral bonus to a PeerSource charity in your
                      name. See our charities <a href="/charities/">here</a>.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="mt-5">
                <h2 className="mb-4">Ready to Make an Introduction?</h2>
                <p style={{ fontSize: "23px", lineHeight: "1.8" }}>
                  If you have someone in mind, reach out to your PeerSource
                  contact or use the <a href="/contact-us">Contact Us</a> {""}
                  feature letting us know about your interest in our Client
                  Referral Program, and any questions you may have.
                </p>
              </div>
            </div>
          </Container>
        </Container>

        <Container
          fluid
          style={{ backgroundColor: "#0d2e4f", padding: "100px 0" }}
        >
          <Container>
            <Container>
              <Row className="d-flex justify-content-center align-items-center">
                <Col lg="4">
                  <div className="ps-vp-box2 text-center">
                    <img
                      className=" rounded-circle"
                      src={image1}
                      alt="IT Talent"
                      width={100}
                      height={100}
                    />

                    <h5 className="m-4">Looking for IT Talent?</h5>
                    <p>
                      Our PeerNetwork model and experienced recruiters allow us
                      to offer a wide range of technical and non-technical
                      talent for our clients.
                    </p>
                    <Link to="/recruiting-services">Learn More</Link>
                  </div>
                </Col>

                <Col lg="4">
                  <div className="ps-vp-box2 text-center">
                    <img
                      className=" rounded-circle"
                      src={image2}
                      alt="Search Job"
                      width={100}
                      height={100}
                    />

                    <h5 className="m-4">Search for Jobs</h5>
                    <p>Check out our current opportunities!</p>
                    <Link to="/jobs">Learn More</Link>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="ps-vp-box2 text-center">
                    <img
                      className=" rounded-circle"
                      src={image3}
                      alt="PeerNetwork"
                      width={100}
                      height={100}
                    />

                    <h5 className="m-4">PeerNetwork</h5>
                    <p>
                      Find out more about our unique recruiting model and how
                      you can earn money on the side while improving your
                      professional network.
                    </p>
                    {/* <Link to="/peernetwork">Learn More</Link> */}
                    <Link to="/contact-us">Learn More</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export default ClientReferralProgram
